@import '~styles/mixins';

.layoutContent {
  width: calc(100vw - 260px);
  height: calc(100vh - 80px);
  overflow: auto;

  &__wrapper {
    padding: 20px;
  }
}