@import '~styles/mixins';

.status {
    display: grid;
    grid-template-columns: auto 1fr 100px auto;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    
    &__save {
        text-align: center;
        justify-content: center;
        
        &[disabled] {
            opacity: 0.2;
        }
    }
    
    &__inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        & > div:first-child {
            border-radius: 12px 0 0 12px;
            color: inherit;
            border-right: 1px solid color(var(--color-main));
            
            input {
                font-weight: 600;
                color: inherit;
            }
        }
        
        & > div:last-child {
            border-radius: 0 12px 12px 0;
        }
        
        input {
            max-width: 100%;
            width: 100%;
            border-radius: inherit;
            background: color(var(--color-white), .05);
            backdrop-filter: blur(22px);
            
            &:hover {
                background: color(var(--color-white), .1);
            }
        }
    }
}