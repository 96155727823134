@import '~styles/mixins';

.layout {
  &__content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 260px 1fr;
    //gap: 20px;

    &__leftPart {
      display: flex;
      height: 100vh;
      flex-direction: column;
    }

    &__rightPart {
      //padding: 20px 100px 20px 20px;
      gap: 20px;
      width: 100%;
      height: 100%;
      
      &__content {
      }
    }
  }
}