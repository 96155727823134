@import '~styles/mixins';

.item {
    width: 100%;
    height: 80px;
    position: relative;
    border-radius: 12px;
    transition: background 0.2s linear;
    cursor: pointer;
    overflow: hidden;
    
    &:hover {
        background: color(var(--color-main-hover));
    }
    
    &__content {
        display: flex;
        padding: 6px;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        height: 100%;
        
        &__header {
            width: 100%;
            height: 40px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            
            &__avatar {
                display: flex;
                flex-direction: row;
                align-items: center;
                
                &__img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                    font-size: 10px;
                }
            }
            
            &__taskName {
                color: color(var(--color-white));
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                text-align: left;
                
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        
        &__footer {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            
            &__date {
                display: flex;
                align-items: center;
                color: color(var(--color-white), 0.6);
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                text-align: right;
            }
        }
    }
}