@import '~styles/mixins';

.linkStatus {
    display: flex;
    align-items: center;
    gap: 10px;
    
    &__button {
        font-weight: 400;
    }
    
    &__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        text-align: center;
        
        &__confirmed{
            color: color(var(--color-green));
        }
        
        &__not_confirmed{
            color: color(var(--color-orange));
        }
    }
}