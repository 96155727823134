@import '~styles/mixins';

.container {
    height: 100%;
    width: fit-content;
    
    &__content {
        display: flex;
        flex-direction: row;
        gap: 10px;
        height: max-content;
        width: max-content;
    }
}
