@import '~styles/mixins';

.searchInput {
  position: relative;
  width: 240px;
  height: 40px;
  padding: 0 14px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: color(var(--color-white), 0.05);

  &_input {
    color: white;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
  }

  &_button {
    display: flex;
    align-items: center;
    justify-content: center;
    &_icon {
    }
  }

}
