@import '~styles/mixins';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__content {
    &_avatar {
      width: 40px;
      height: 40px;
    }
    &__avatarGallery {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      &__img {
        width: 30px;
        height: 30px;
        margin-right: -8px;
        border-radius: 50%;
        object-fit: cover;
        font-size: 10px;
      }
    }

    &__button {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      padding: 10px 16px;
      background: color(var(--color-white),0.05);
      transition: 0.2s background linear;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.dateYellow {
  color:color(var(--color-yellow));
}
.dateRed {
  color:color(var(--color-red));
}