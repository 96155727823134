@import '~styles/mixins';


.kanbanRow {
  height: initial;
  width: 330px;
  min-width: 330px;
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;

  &__content {
    padding: 10px;
    gap: 10px;

    &__header {
      display: flex;
      flex-direction: row;
      height: 40px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      &__title {
        color: color(var(--color-white));
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    &__items {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }
}