@import '~styles/mixins';

.header {
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: end;
    width: 100%;
    padding: 20px;
    background: color(var(--color-background));
    
    &__container {
        &__content {
            gap: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            
            &__timeTracker {
                background: color(var(--color-main));
                border-radius: 200px;
                padding: 0 10px;
                height: 40px;
                display: flex;
                align-items: center;
                position: relative;
            }
            
            &__user {
                display: flex;
                gap: 10px;
                align-items: center;
                flex-direction: row;
                height: 40px;
                padding: 0 12px 0 6px;
                border-radius: 200px;
                user-select: none;
                transition: background .3s;
                cursor: pointer;
                
                &:hover {
                    background: color(var(--color-white), 0.05);
                }
                
                &__avatar {
                    width: 32px;
                    height: 32px;
                    
                    &__img {
                        width: 100%;
                        height: 100%;
                        border-radius: 200px;
                        object-fit: cover;
                    }
                }
                
                &__info {
                    color: color(var(--color-white));
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }
}