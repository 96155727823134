@import '~styles/mixins';

.container {
  max-width: 400px;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.dialog{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: 20px;
  background: rgb(30, 28, 39);
}

.title {
  width: 100%;
  color: color(var(--color-white));
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.lang {
  color: color(var(--color-white));
  font-weight: 400;
  font-size: 14px;
}

.exitButton{
  padding:8px;
  background: color(var(--color-second));
  border-radius: 200px;
  transition: background 50ms linear;
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover {
    background: color(var(--color-second-hover));
  }
}