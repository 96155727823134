@import '~styles/mixins';

.badge {
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 14px;
    font-weight: 500;
    color: color(var(--color-white));
    text-transform: capitalize;
    width: fit-content;
    
    &_HIGH {
        background: color(var(--color-red))
    }
    
    &_MEDIUM {
        background: color(var(--color-orange))
    }
    
    &_LOW {
        background: color(var(--color-green))
    }
}