@import '~styles/mixins';

.aside {
  position: fixed;
  height: 100%;
  z-index: 999;
  width: 260px;
  background: color(var(--color-background));

  &::-webkit-scrollbar {
    display: none;
  }

  .content {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .navigation {
      padding: 20px 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .navColumn {
        display: flex;
        flex-direction: column;
        gap: 8px;
        background: color(var(--color-white), 0.05);
        border-radius: 12px;
        padding: 14px;

        &__header {
          color: color(var(--color-white), 0.5);
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          text-transform: capitalize;
        }

        &__item {
          width: 100%;
          padding: 14px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }
}