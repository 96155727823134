@import '~styles/mixins';

.column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: color(var(--color-white));
    border-bottom: 1px solid color(var(--color-main));
    position: relative;

    & > div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &Active {
        cursor: pointer;
        transition: background .1s linear;

        &:hover {
            background: color(var(--color-main), 0.2);
        }
    }
}
