.container {
  display: flex;
  flex-direction: column;
  width:100%;
  height: 460px;
  gap: 20px;
  overflow-y: scroll;

  &__tasks{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
  }
  
  &__header {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
  }
}
