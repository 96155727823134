@import '~styles/mixins';

.tab {
  position: relative;
  cursor: pointer;
  width: fit-content;
  padding: 4px 0;
  font-size: 14px;
  color: color(var(--color-white), 0.6);
  
  &::before {
    content: '';
    width: 0;
    height: 1px;
    background: var(--linear-gradient-blue);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -4px;
    transition: width 0.2s linear;
  }

  &__active {
    color: color(var(--color-white));
    
    &::before {
      width: 100%;
    }
  }
}