@import '~styles/mixins';

.cropper{
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23FFFFFF80' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 12px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    min-height: 120px;
    &__close{
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 1;
    }
    &__input{
        // display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        opacity: 0;
        cursor: pointer;
        z-index: 0;
    }
    &__img{
        width: 60px;
        height: 60px;
        border-radius: 100%;
    }
    &__placeholder{
        color: color(var(--color-white));
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }
    &__crop{
        max-height: 300px;
        border-radius: 12px;
        overflow: hidden;
    }
    &__save{
        position: absolute !important;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
}