.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    height: 100%;
  }
}