@import './mixins';

.cropper{
    &-crop-box, &-view-box{
        border-radius: 50%;
    }
    &-view-box {
        box-shadow: 0 0 0 1px color(var(--color-white), 0.4);
        outline: 0;
    }
    &-point.point{
        &-ne, &-nw, &-sw, &-se{
            background: color(var(--color-white), 0.2);
        }
        &-e, &-n, &-w, &-s{
            background: color(var(--color-white), 0.2);
            display: none;
        }
        &-se{
            &::before{
                content: none;
            }
        }
    }
    &-line{
        background: none;
        background: color(var(--color-white), 0.4);
        &-e, &-w{
            width: 2px !important;
        }
        &-n, &-s{
            height: 2px !important;
        }
    }
    &-line.line{
        &-e, &-w{
            width: 2px !important;
        }
        &-n, &-s{
            height: 2px !important;
        }
    }
    &-face{
        background: black;
    }
    &-center{
        background-color: purple;
        display: none;
    }
}