@import '~styles/mixins';

.select{
    position: relative;
    width: 100%;
    min-width: 100px;
    color: color(var(--color-white));
    font-size: 14px;
    width: fit-content;
    &__block{
        cursor: pointer;
        padding: 8px 10px;
        height: 40px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: color(var(--color-second));
        transition: 0.3s background;
        &:hover{
            background: color(var(--color-second-hover));
        }
        &_open{
            background: color(var(--color-second-hover));
        }
        &__arrow{
            display: flex;
            width: 24px;
            align-items: center;
            justify-content: center;
            border-radius: 200px;
            height: 24px;
            background: color(var(--color-white), 0.05);
            transition: 0.3s transform;
            svg{
                width: 12px;
                height: 12px;
            }
            &_active{
                transform: scaleY(-1);
            }
        }
        &__value{
           display: block;
            &_array{
                display: flex;
                align-items: center;
            }
            &__item{
                margin-left: -10px;
                display: flex;
                &:first-child{
                    margin-left: 0;
                }
                &__overflow{
                    margin-left: -10px;
                    background: color(var(--color-white), 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 2px;
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    span{
                        display: block;
                        width: 2px;
                        height: 2px;
                        border-radius: 100%;
                        background: color(var(--color-white));
                    }
                }
            }
        }
    }
    &__options{
        padding: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s opacity, 0.2s top, 0.2s visibility;
        position: absolute;
        top: 100%;
        right: 0;
        border-radius: 12px;
        background: color(var(--color-second));
        z-index: 1;

        &_open{
            opacity: 1;
            visibility: visible;
            top: calc(100% + 10px);
        }
        &__wrapper{
            position: relative;
            background: color(var(--color-second));
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-radius: 12px;
        }
        &__item{
            padding: 10px;
            border-radius: 100px;
            align-items: center;
            transition: 0.3s background;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            div {
                display: flex;
                align-items: center;
            }

            &__active {
                background: var(--linear-gradient-blue);
            }

            &__noActive {
                &:hover{
                    background: color(var(--color-second-hover));
                }
            }
        }
    }
}

