@import '~styles/mixins';


.loader {
    width: 100%;
    height: 100%;
    display: inline-block;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    background: color(var(--color-white), .05);
    
    &::after {
        position: absolute;
        border-radius: 50%;
        top: 0;
        left: 0;
        content: '';
        animation: loader 1s ease-in infinite;
        width: 100%;
        height: 100%;
        background: #FFF;
    }
}

@keyframes loader {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
