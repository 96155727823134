@import '~styles/mixins';


.item {
    padding: 8px 14px;
    width: 100%;
    position: relative;
    transition: background 0.2s ease-in-out;
    border-bottom: 1px solid color(var(--color-white), 0.05);

    &:first-child {
        border-radius: 12px 12px 0 0;
    }

    &:last-child {
        border-top: 1px solid color(var(--color-white), 0.05);
        border-radius: 0 0 12px 12px;
    }

    &:hover {
        background: color(var(--color-white), 0.05);
    }

    &:last-child {
        border-bottom: none;
    }

    &__component {
        width: 100%;
        padding: 8px 14px;
        height: 100%;
    }

    &__defaultCard {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &__leftPart {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            &__text {
                color: color(var(--color-white));
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                text-align: left;
            }
        }

        &__rightPart {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            &__tooltip {
                color: color(var(--color-white), 0.6);
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                text-align: right;
            }
        }
    }
}
