@import '~styles/mixins';

.tagCreator {
  display: flex;
  gap: 6px;
  width: 360px;
  flex-wrap: wrap;
  &_input {
    height: 30px;
    width: 140px;
    padding: 0 10px;
    border-radius: 200px;
    background: color(var(--color-white), 0.05);
    color: color(var(--color-white), 0.6);
  }
}