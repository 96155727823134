@import '~styles/mixins';

.select{
  position: relative;
  color: color(var(--color-white));
  font-size: 14px;
  width: fit-content;
  &__block{
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: color(var(--color-second));
    transition: 0.3s background;
    &:hover{
      background: color(var(--color-second-hover));
    }
    &_open{
      background: color(var(--color-second-hover));
    }
  }
  &__options{
    padding: 8px;
    width: 160px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s opacity, 0.2s top, 0.2s visibility;
    position: absolute;
    top: 100%;
    right: 0;
    border-radius: 12px;
    background: color(var(--color-second));
    z-index: 1;

    &_open{
      opacity: 1;
      visibility: visible;
      top: calc(100% + 10px);
    }
    &__wrapper{
      position: relative;
      background: color(var(--color-second));
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 12px;
    }
    &__item{
      padding: 10px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: 0.3s background;
      cursor: pointer;

        &:hover{
          background: color(var(--color-second-hover));
        }

      &__icon {
        display: flex;
        align-items: center;
        svg {
          width:18px ;
          height: 18px;
        }
      }
      &__text {
        white-space: nowrap;
      }
    }
  }
}

