@import '~styles/mixins';

.pagination {
    display: flex;
    gap: 5px;
    color: color(var(--color-white));

    &__prev_button {
        rotate: 90deg;
    }

    &__next_button {
        rotate: -90deg;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        font-size: 14px;
        line-height: 17px;
        background: color(var(--color-white), 0.05);
        border-radius: 100px;
        transition: background 0.2s linear;

        &:disabled,
        &:disabled:hover {
            background: transparent;
        }

        &:hover {
            background: color(var(--color-white), 0.1);
        }

        &.active {
            background: var(--linear-gradient-blue);
        }
    }
}
