.container {
  height: 100%;
  width: fit-content;
  
  &__content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: max-content;
    width: 100%;

    &__text {
     font-weight: 400;
    }
  }
}
