@import '~styles/mixins';

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    &__content {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;
    }
}