@import '~styles/mixins';

.container {
  width: 100%;
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;

  &__header {
    color: color(var(--color-white));
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }
}
