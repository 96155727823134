@import 'styles/mixins';

.userCard{
  width: 100%;
  height: 40px;
  display: flex;
  gap: 10px;
  align-items: center;

  &__picture {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__img {
      border-radius: 200px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__name {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
  }
}

