@import '~styles/mixins';

.select{
    position: relative;
    width: fit-content;
    min-width: 100px;
    color: color(var(--color-white));
    font-size: 14px;
    &__block{
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: color(var(--color-second));
        transition: 0.3s background;
        &:hover{
            background: color(var(--color-second-hover));
        }
        &_open{
            background: color(var(--color-second-hover));
        }
        &__arrow{
            display: flex;
            transition: 0.3s transform;
            svg{
                width: 12px;
                height: 12px;
            }
            &_active{
                transform: scaleY(-1);
            }
        }
        &__value{
           display: block;
            &_array{
                display: flex;
                align-items: center;
            }
            &__item{
                margin-left: -8px;
                display: flex;
                width: 25px;
                height: 25px;
                font-size: 10px;
                &:first-child{
                    margin-left: 0;
                }
                &__overflow{
                    margin-left: -8px;
                    background: color(var(--color-white), 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 2px;
                    width: 25px;
                    height: 25px;
                    border-radius: 100%;
                    span{
                        display: block;
                        width: 2px;
                        height: 2px;
                        border-radius: 100%;
                        background: color(var(--color-white));
                    }
                }
            }
        }
    }
    &__options{
        opacity: 0;
        visibility: hidden;
        transition: 0.2s opacity, 0.2s top, 0.2s visibility;
        position: absolute;
        top: 100%;
        right: 0;
        border-radius: 12px;
        background: color(var(--color-second));
        z-index: 1;
        &::before{
            content: "";
            display: block;
            position: absolute;
            top: -4px;
            right: 25px;
            width: 8px;
            height: 8px;
            background: color(var(--color-second));
            transform: rotate(45deg);
        }
        &_open{
            opacity: 1;
            visibility: visible;
            top: calc(100% + 10px);
        }
        &__wrapper{
            position: relative;
            background: color(var(--color-black), 0.15);
            display: flex;
            flex-direction: column;
            gap: 1px;
            border-radius: 12px;
            width: max-content;
        }
        &__item{
            padding: 8px 14px;
            transition: 0.3s background;
            cursor: pointer;
            background: color(var(--color-second));
            display: flex;
            align-items: center;
            gap: 8px;
            &:first-child{
                border-radius: 12px 12px 0 0;
            }
            &:last-child{
                border-radius: 0 0 12px 12px;
            }
            &:hover{
                background: color(var(--color-second-hover));
            }
            &__checkbox{
                margin-left: auto;
            }
            &__img{
                width: 25px;
                height: 25px;
                border-radius: 50%;
                object-fit: cover;
                font-size: 10px;
            }
        }
    }
}

