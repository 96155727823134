@import '~styles/mixins';


.deadlineNotification {
  width: 100%;

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100%;

    &__text {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      box-shadow: none;
      text-align: left;
      text-transform: capitalize;
    }
    &__date {
      color: color(var(--color-white));
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
    }
  }
}
