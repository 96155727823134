@import '~styles/mixins';

.logotype {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}