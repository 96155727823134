@import '~styles/mixins';

.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    background-color: color(var(--color-main));
    transition: background-color .3s linear, backdrop-filter .3s linear;
    backdrop-filter: blur(12px);
    
    &__disabled {
        background-color: color(var(--color-main), 0);
        pointer-events: none;
        backdrop-filter: blur(0);
    }
}

.loader {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 4px solid #ffffff01;
    user-select: none;
    pointer-events: none;
    transition: transform .2s linear, opacity .2s linear;
    
    &__disabled {
        transform: scale(0);
        opacity: 0;
    }
    
    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        z-index: 4;
        opacity: 0.4;
        animation: opacity 3s ease-out infinite;
        
        &:after {
            content: '...';
            animation: dots 3s linear infinite;
        }
    }
    
    &__progress {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: rotation 3s linear infinite;
        
        &::after {
            content: '';
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 3px solid;
            border-color: #ffffff transparent transparent;
        }
    }
}

@keyframes dots {
    0% {
        content: '...';
    }
    33% {
        content: '.';
    }
    66% {
        content: '..';
    }
    100% {
        content: '...';
    }
}

@keyframes opacity {
    0% {
        opacity: 0.4;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.4;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.waves {
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
}

.parallax > use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }
}