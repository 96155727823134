@import "mixins";
@import "variables";

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  background-color: color(var(--color-background));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow: hidden;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 600;
  color: inherit;
  margin: 0;
}

h1{
  font-size: 32px;
}
h2{
  font-size: 24px;
}
h3{
  font-size: 20px;
}
h4{
  font-size: 16px;
}
h5{
  font-size: 14px;
}
h6{
  font-size: 12px;
}


a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  background-color: transparent;
  border: none;
}

ul{
  list-style: none;
}

input{
  color: inherit;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
}

html, body {

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--linear-gradient-blue);
    border-radius: 200px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}


.color {
  &-error {
    color: color(var(--color-red));
  }
}