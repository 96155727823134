@import '~styles/mixins';

.error {
    width: 100%;
    height: 100%;
    background: color(var(--color-background), 1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        width: 60%;
        height: 60%;
    }
}

.image {
    width: inherit;
    height: inherit;
    
    img {
        object-fit: cover;
        width: inherit;
        height: inherit;
    }
}