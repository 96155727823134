@import '~styles/mixins';

.row {
    display: grid;
    height: 60px;
    padding: 0 10px;
    align-items: center;
    position: relative;
    overflow: visible;
}

.usuallyRow {
    cursor: pointer;

    &:hover {
        background: color(var(--color-main), 0.2);
    }
}


.titleContainer {
    width: 100%;
    height: inherit;
}
