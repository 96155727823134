@import '~styles/mixins';

.reportsHeaderItem {
    width: 100%;
    height: 100%;
    
    &__userRow {
        width: 100%;
    }
    
    &__content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        height: 0;
        transition: height 0.2s ease-out;
        overflow: hidden;
        
        &__table {
            width: 100%;
            height: 100%;
        }
    }
}