@import '~styles/mixins';

.rippleContainer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
    
    span {
        position: absolute;
        transform: scale(0);
        border-radius: 100%;
        opacity: 0.25;
        animation-name: ripple;
        animation-duration: 850ms;
        background: #fff;
    }
    
    @keyframes ripple {
        to {
            opacity: 0;
            transform: scale(2);
        }
    }
}