@import '~styles/mixins';

.kanbanItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: background 0.2s linear;
    background: color(var(--color-white), .05);
    border-radius: 20px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer !important;
    backdrop-filter: blur(22px);
    
    &:hover {
        background: color(var(--color-white), .1);
    }
    
    &__content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        
        &__heading {
            color: color(var(--color-white));
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        
        &__description {
            color: color(var(--color-white), 0.6);
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }
        
        &__footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            
            &__timeKeeper {
            
            }
            
            &__avatarGallery {
                display: flex;
                flex-direction: row;
                align-items: center;
                
                &__img {
                    width: 25px;
                    height: 25px;
                    margin-right: -8px;
                    border-radius: 50%;
                    object-fit: cover;
                    font-size: 10px;
                }
            }
        }
        
        &__badges {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 100%;
            justify-content: space-between;
            color: color(var(--color-white));
            
            &__tags {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                width: 100%;
                gap: 4px;
            }
        }
        
        
    }
}

.overdueDeadlineTask{
    box-shadow: var(--box-shadow-red);
    color: color(var(--color-red));
}

.warningDeadlineTask{
    color:color(var(--color-yellow)) ;
    box-shadow: var(--box-shadow-yellow);
}