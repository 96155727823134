@import '~styles/mixins';

.userRowInfo {
  display: flex;
  width: 100%;
  height: 60px;
  border-radius: 12px;
  background-color: color(var(--color-white), 0.05);
  position: relative;
  overflow: hidden;
  transition: background-color .1s linear;
  
  &:hover {
    background-color: color(var(--color-white), 0.1);
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 100%;
    width: 100%;

    &__leftPart {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      &__nickname {
        color: color(var(--color-white));
        font-size: 14px;
        font-weight: 400;
      }

      &__avatar {
        width: 40px;
        height: 40px;

        &__img {
          width: 100%;
          height: 100%;
          border-radius: 200px;
          object-fit: cover;
        }
      }
    }

    &__rightPart {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      &__overallTime {
        color: color(var(--color-white));
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: center;
      }

      &__arrow {
        display: flex;
        align-items: center;
        padding: 4px;
        transition: transform 100ms linear;

        svg {
          width: 15px;
          height: 15px;
        }

        &_active {
          transform: scaleY(-1);
        }
      }
    }
  }
}