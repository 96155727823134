@import '~styles/mixins';

.container {
  position: relative;
  width: 360px;
  height: auto;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  backdrop-filter: blur(40px);
  background: var(--background-main);
}

.container::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 16px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent color(var(--color-white), 0.05) transparent transparent;
}


@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.popupEnter {
  animation: slideInFromRight 0.3s ease-out forwards;
}

@keyframes slideOutToRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(40px);
  }
}

.popupExit {
  animation: slideOutToRight 0.3s ease-out forwards;
}
