@import '~styles/mixins';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 135px;
    height: 100%;
    border-radius: 100px;
    z-index: 1;
    position: relative;
    
    &__content {
        display: flex;
        height: 100%;
        gap: 10px;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        
        &__taskTimer {
            display: flex;
            height: 100%;
            align-items: center;
            width: 60px;
            font-weight: 400;
            color: color(var(--color-white));
            font-size: 14px;
        }
        
        &__taskControl {
            display: flex;
            align-items: center;
            height: 100%;
            
            &__taskControlButton {
                display: flex;
                align-items: center;
            }
        }
    }
}