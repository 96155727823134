@import '~styles/mixins';

.modal {
    position: fixed;
    top: calc(70px + (70px / 2) + 3px);
    right: 0;
    width: fit-content;
    height: 100%;
    // box-shadow: 0px 0px 20px 0px color(var(--color-black), 0.25);
    border-radius: 12px 0 0 0;
    background: color(var(--color-second));
    color: color(var(--color-white));
    z-index: 999;

    &_enter {
        transform: translateX(100%);

        &_active {
            transform: translateX(0);
            transition: 0.3s transform, 0.3s opacity;
        }
    }

    &_exit {
        transform: translateX(0);

        &_active {
            transform: translateX(100%);
            transition: 0.3s transform, 0.3s opacity;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 1px;
        height: 100%;
        border-radius: 12px 0 0 0;
        overflow: hidden;
    }

    &__close {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 22px;
        top: 15px;
        border-radius: 200px;
        border: 1px solid color(var(--color-white), 0.05);
        background: color(var(--color-main));
        transform: translateX(-100%);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 80%;
            height: 80%;
        }
    }

    &__header {
        background: color(var(--color-main));
        padding: 20px 30px;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: baseline;
        justify-content: space-between;
        gap: 10px;
    }

    &__block {
        display: flex;
        height: 100%;
        gap: 1px;


        &_right, &_left {
            background: color(var(--color-main));
            padding: 10px 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        &_left {
            width: 530px;
            padding: 20px 30px;
            // width: 100%;
        }

        &_right {
            width: 360px;

            // width: 100%;
            & > div {
                display: grid;
                grid-template-columns: 120px 1fr;
                gap: 20px;
                align-items: center;

                & > p {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }
}
