@import '~styles/mixins';

.statuses {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
    &__main {
        display: flex;
        flex-direction: column;
    }
    
    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}